import React, { FunctionComponent } from "react";

interface ViewProfileProps {

}

export const ViewProfile:FunctionComponent<ViewProfileProps> = ({  }) => {

    return (
        <>
            ViewProfile
        </>
    )
};
